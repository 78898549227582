import styled, { css as styledCSS } from "styled-components";

import { css, BUTTON, getCSSVarString, SECTION, ICON } from "Libs/themes";

export const Wrapper = styled.div`
  margin-bottom: 8px;
  border-radius: 4px;
  background-color:var(--section-snow-background-color,var(--section-snow,var(--snow)));
  &:not(.open):hover {
    background-color:var(--section-snow-background-color,var(--section-snow,var(--snow)));
  }

  &.open {
    margin: 16px 0;
    background-color:var(--section-snow-background-color,var(--section-snow,var(--snow)));
    box-shadow: ${props =>
      props.theme.name === "dark"
        ? "0px 6px 44px #01001A"
        : "0px 10px 20px rgba(75, 97, 128, 0.08)"};

    .setting-line {
      background-color:var(--section-ice-background-color,var(--section-ice,var(--ice)));
      border-radius: 4px 4px 0 0;
      &:hover {
        background-color:var(--section-ice-background-color,var(--section-ice,var(--ice)));
        box-shadow: none;
        .open-text {
          opacity: 0;
        }
      }
    }
    .action svg {
      transform: rotate(180deg);
    }
  }

  form {
    padding: 24px 32px;
    .toggle-field {
      margin-bottom: 24px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .toggle-field + .toggle-field,
    .toggle-field + .actions {
      border-top: 1px solid
        var(--section-skye-light-border-color,var(--section-skye-light,var(--skye-light)));
      padding-top: 24px;
    }
    .select-box__control {
      min-height: 38px;
      height: 40px;
      .select-box__value-container {
        height: 40px;
        .select-box__single-value {
          height: 40px;
          line-height: 38px;
        }
      }
    }
  }
`;

export const Layout = styled.div`
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
  min-height: 60px;
  box-sizing: border-box;
  font-size: 16px;
  color:var(--section-ebony-light-color,var(--section-ebony-light,var(--ebony-light)));
  border-radius: 4px;
  text-decoration: none;

  ${props =>
    props.variant === "mobile" &&
    styledCSS`
      padding: 0 24px;

      @media (min-width: 768px) {
        padding: 0 32px;
      }
    `};

  .open-text {
    opacity: 0;
    color:var(--button-skye-color,var(--button-skye,var(--skye)));
    &:first-letter {
      text-transform: uppercase;
    }
  }
  button {
    padding: 0;
    margin: 0;
    &:not([data-uikit]) {
      cursor: pointer;
      background: transparent;
      padding: 0;
      display: flex;
      align-items: center;
      .chevron svg path {
        fill:var(--icon-granite-fill,var(--icon-granite,var(--granite)));
      }
      &:hover,
      &:focus {
        background: transparent;
        outline: none;
        box-shadow: none;
        border-color: transparent;
        .open-text {
          opacity: 1;
        }
        .chevron svg path {
          fill:var(--button-skye-fill,var(--button-skye,var(--skye)));
        }
      }
    }
  }
  hr {
    background-color:var(--section-skye-light-background-color,var(--section-skye-light,var(--skye-light)));
  }
  &:hover {
    background-color:var(--setting-line-hover-section-background-color,var(--section-snow-background-color,var(--section-snow,var(--snow))));
    box-shadow: ${props =>
      props.theme.name === "dark"
        ? "0px 6px 44px #01001A"
        : "0px 10px 20px rgba(75, 97, 128, 0.08)"};
    .open-text {
      opacity: 1;
    }
    .chevron svg path {
      fill:var(--section-skye-fill,var(--section-skye,var(--skye)));
    }
  }
`;

export const Info = styled.div`
  width: ${props => props.width || "100%"};
`;
