import React, { useCallback, useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { injectIntl } from "react-intl";
import { LiveMessage } from "react-aria-live";

import { semiBoldAlias } from "Libs/theme";
import {
  css,
  BUTTON,
  SECTION,
  MENU,
  MENU_ITEM,
  getCSSVarString
} from "Libs/themes";
import Checkbox from "Components/fields/CheckboxField";
import CloseIcon from "./IconClose";
import IconFilter from "./IconFilter";

const Filter = ({ options, activeOptions, onChange }) => {
  const panelNode = useRef();
  const [expanded, setExpanded] = useState(false);

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  const handleClickOutside = useCallback(
    e => {
      if (panelNode?.current?.contains(e.target)) {
        return;
      }

      if (expanded) {
        setExpanded(false);
      }
    },
    [expanded]
  );

  const handleKeyDown = useCallback(
    e => {
      const ESCAPE_KEYCODE = 27;
      if (e.keyCode === ESCAPE_KEYCODE && expanded) {
        setExpanded(false);
      }
    },
    [expanded]
  );

  const handleExternalEvents = useCallback(() => {
    if (expanded) {
      document.addEventListener("click", handleClickOutside);
      document.addEventListener("keydown", handleKeyDown);
    } else {
      document.removeEventListener("click", handleClickOutside);
      document.removeEventListener("keydown", handleKeyDown);
    }

    return () => {
      document.removeEventListener("click", handleClickOutside);
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [expanded, handleClickOutside, handleKeyDown]);

  useEffect(handleExternalEvents);

  return (
    <Container>
      <Aria expanded={expanded} />
      <Input
        onClick={toggleExpand}
        aria-label="Filter activities"
        aria-haspopup="true"
        aria-expanded={expanded}
      >
        <InputText>Filter</InputText>
        <IconFilter />
      </Input>
      {expanded && (
        <Panel ref={panelNode}>
          <Header>
            Filter activity{" "}
            <CloseButton onClick={toggleExpand}>
              <CloseIcon />
            </CloseButton>
          </Header>
          <Body>
            {options.map(o => {
              const isActive = activeOptions.includes(o.value);
              const label = (
                <OptionLabel active={isActive}>{o.label}</OptionLabel>
              );
              return (
                <Option key={o.value}>
                  <Checkbox
                    onChange={onChange}
                    label={label}
                    type="checkbox"
                    name={o.value}
                    forId={o.value}
                    value={isActive}
                  />
                </Option>
              );
            })}
          </Body>
        </Panel>
      )}
    </Container>
  );
};

Filter.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired
    })
  ),
  activeOptions: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func.isRequired
};

const Aria = expanded =>
  expanded ? (
    <LiveMessage
      message="Filter popup menu has been opened."
      aria-live="polite"
    />
  ) : (
    <LiveMessage
      message="Filter popup menu has been closed."
      aria-live="polite"
    />
  );

const Input = styled.button`
  ${semiBoldAlias};
  font-size: 14px;
  line-height: 16px;
  padding: 7px 6px;
  background: transparent;
  border: none;
  color:var(--section-ebony-light-color,var(--section-ebony-light,var(--ebony-light)));

  &:hover,
  &:active {
    cursor: pointer;
    color:var(--button-skye-color,var(--button-skye,var(--skye)));
    border: none;
  }

  &:focus {
    outline-color:var(--button-skye-dark-outline-color,var(--button-skye-dark,var(--skye-dark)));
    box-shadow: var(--section-box-shadow,var(--box-shadow))
      var(--section-skye-dark-box-shadow-color,var(--section-skye-dark,var(--skye-dark)));
  }
`;

const CloseButton = styled(Input)`
  color:var(--button-granite-color,var(--button-granite,var(--granite)));
`;

const InputText = styled.span`
  margin-right: 10px;
  vertical-align: text-top;
`;

const OptionLabel = styled.span`
  font-size: 14px;
  ${props => props.active &&"color:var(--menu-item-skye-color,var(--menu-item-skye,var(--skye)))"};
`;

const Header = styled.header`
  ${semiBoldAlias};
  display: flex;
  justify-content: space-between;
  padding: 13px 16px 10px 24px;
  border-bottom: 1px solid
    var(--section-periwinkle-grey-border-color,var(--section-periwinkle-grey,var(--periwinkle-grey)));
  font-size: 14px;
  line-height: 28px;
  color:var(--section-ebony-light-color,var(--section-ebony-light,var(--ebony-light)));

  &:svg {
    color: red;
  }
`;

const Body = styled.div`
  padding: 15px 24px;
`;

const Option = styled.div`
  margin-bottom: -16px;
`;

const Container = styled.div`
  position: relative;
`;

const Panel = styled.div`
  background-color:var(--section-snow-background-color,var(--section-snow,var(--snow)));
  box-shadow: var(--menu-box-shadow-box-shadow,var(--menu-box-shadow,var(--box-shadow)))
    var(--menu-ebony-light-box-shadow-color,var(--menu-ebony-light,var(--ebony-light)));
  width: 300px;
  position: absolute;
  right: 0;
  top: 24px;
  display: flex;
  flex-direction: column;
  border-radius: 2px;
  z-index: 1;
`;

export default injectIntl(Filter);
