import styled from "styled-components";

import { css, LINK, getCSSVarString, SECTION, ICON } from "Libs/themes";

import Link from "../Link";

export const Layout = styled(Link)`
  var(--section-slate-color,var(--section-slate,var(--slate)));
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-transform: capitalize;
  font-size: 13px;
  line-height: 18px;

  svg {
    margin-right: 12px;
    path {
      fill:var(--icon-slate-fill,var(--icon-slate,var(--slate)));
    }
  }

  &:hover:not(:focus):after {
    content: none;
  }

  &:hover {
    color:var(--link-skye-color,var(--link-skye,var(--skye)));
    svg path {
      fill:var(--link-skye-fill,var(--link-skye,var(--skye)));
    }
  }
`;
