import styled from "styled-components";

import { semiBoldAlias } from "Libs/theme";
import { css, LINK } from "Libs/themes";

export const Wrapper = styled.div`
  a {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    color:var(--link-slate-color,var(--link-slate,var(--slate)));
    line-height: 18px;
    text-decoration: none;
    ${semiBoldAlias};
    cursor: pointer;
    svg {
      margin-right: 12px;
      path,
      rect {
        color:var(--link-slate-color,var(--link-slate,var(--slate)));
      }
    }
    &:hover {
      color:var(--link-skye-color,var(--link-skye,var(--skye)));
      svg {
        rect,
        path {
          fill:var(--link-skye-fill,var(--link-skye,var(--skye)));
        }
      }
    }
  }
  margin-bottom: 16px;
`;
