import styled from "styled-components";

import { getCSSVarString, SECTION } from "Libs/themes";

export const Layout = styled.span`
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  text-transform: capitalize;

  color: ${({ priority }) => {
    switch (priority) {
      case "low":
        return"var(--section-granite-color,var(--section-granite,var(--granite)))";
      case "normal":
        return"var(--section-slate-color,var(--section-slate,var(--slate)))";
      case "high":
        return"var(--section-fire-color,var(--section-fire,var(--fire)))";
      case "urgent":
        return"var(--section-red-color,var(--section-red,var(--red)))";
      default:
        return"var(--section-ebony-light-color,var(--section-ebony-light,var(--ebony-light)))";
    }
  }};
`;
