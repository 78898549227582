import styled from "styled-components";
import { semiBoldAlias } from "Libs/theme";
import { css, SECTION, BUTTON, LINK } from "Libs/themes";

import Heading6 from "Components/styleguide/Heading6";
import Label from "Components/fields/Label";
import DefaultButtonWrapper from "Components/ButtonWrapper";

export const Flex = styled.div`
  display: flex;
  justify-content: ${props =>
    !props.create && props.shouldRenderSaveCancelButtons
      ? "space-between"
      : "flex-end"};
`;

export const Wrapper = styled.div`
  display: flex;
  flex-shrink: 0;
  border-radius: 4px;
  &.add-form {
    background-color:var(--section-transparent-background-color,var(--section-transparent,var(--transparent)));
    border-radius: 0 0 4px 4px;
    margin-bottom: ${props => (!props.create ? "16px" : "8px")};
  }
  .description {
    font-size: 15px;
    color:var(--section-ebony-light-color,var(--section-ebony-light,var(--ebony-light)));
    margin-bottom: 24px;
  }
  .warning {
    font-size: 13px;
    line-height: 18px;
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    color:var(--section-ebony-light-color,var(--section-ebony-light,var(--ebony-light)));
    ${semiBoldAlias};
    svg {
      margin-right: 24px;
    }
  }
  hr {
    background-color:var(--section-skye-light-background-color,var(--section-skye-light,var(--skye-light)));
  }
`;

export const Form = styled.form`
  ${props => (!props.create ? "padding: 32px" : "")};
  width: 100%;
  box-sizing: border-box;
  .field:last-child .field {
    margin-bottom: 0;
  }
`;

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const CheckBoxInputWrapper = styled.div`
  .checkbox-field {
    margin-bottom: 40px;
  }
`;

export const EnvironmentPermissions = styled.div`
  margin-bottom: 24px;
  hr {
    margin: 0;
  }
`;

export const Btn = styled.div`
  opacity: 0;
  font-size: 14px;
  line-height: 56px;
  color:var(--button-skye-color,var(--button-skye,var(--skye)));
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

export const PermissionWrapper = styled.div`
  &:hover {
    ${Btn} {
      opacity: 1;
    }
  }
  &.environment-permission {
    background: transparent;
    padding: 0;
    margin-bottom: 40px;
    label {
      margin-bottom: 8px;
      &:first-of-type {
        width: 315px;
      }
    }
    .dropdown > div {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  &.line-permission {
    padding: 0;
    line-height: 56px;
    width: 100%;
    box-sizing: border-box;
    .field {
      margin-bottom: 0;
      line-height: 56px;
    }
    label {
      width: 200px;
      margin: 0;
      font-size: 13px;
      ${semiBoldAlias()};
      height: 56px;
      line-height: 56px;
    }
    .dropdown {
      & > div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
      }
    }
  }

  .select-box {
    flex: 1;
    width: auto;
    height: auto;
    margin-right: 14px;
  }

  .select-box__control {
    border: none;
    padding: 0;
    line-height: 40px;
    height: 40px;
    width: 264px !important;
    max-width: 100%;
    box-sizing: border-box;
    font-size: 14px;
  }
  .select-control {
    width: 164px;
  }
  .select-box__indicators {
    align-items: normal;
  }
  .select-box__dropdown-indicator {
    margin: 9px 0 0 0;
  }
`;

export const LoadingWrapper = styled.div`
  background-color:var(--section-ice-background-color,var(--section-ice,var(--ice)));
  padding: 0 16px;
  line-height: 42px;
  margin-bottom: 32px;
`;

export const EnvironmentHeaders = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  label:first-child {
    width: 200px;
  }
  label + label {
    flex: 1;
  }
`;

export const More = styled.div`
  display: flex;
  margin: 16px 0;
  text-align: center;
`;
export const BtnMore = styled.a`
  margin: 0 auto;
  font-size: 14px;
  line-height: 20px;
  color:var(--link-skye-color,var(--link-skye,var(--skye)));
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

export const EnvironmentsListWrapper = styled.div`
  margin-left: 25px;
`;

export const Title = styled(Heading6)`
  ${semiBoldAlias()};
  font-size: 14px;
  line-height: 24px;
`;

export const TitleWrapper = styled.div`
  display: flex;
  h6 + div {
    margin-left: 12px;
  }
`;

export const PermissionTypeLabel = styled(Label)`
  max-width: 299px;
`;

export const ButtonWrapper = styled(DefaultButtonWrapper)`
  display: flex;
  flex-direction: ${props => (props.create ? "row-reverse" : "row")};
`;
