import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";

import useDecodedParams from "Hooks/useDecodedParams";
import useSelectorWithUrlParams from "Hooks/useSelectorWithUrlParams";

import Code from "Components/Code";
import { getEnvironmentGitCommand } from "Libs/utils";

import { environmentSelector } from "Reducers/environment";

import ActionDropdown from "Components/ActionDropdown";
import CopyableArea from "Components/CopyableArea";
import EnvironmentRoutes from "Containers/EnvironmentRoutes";

import * as S from "./styles";

const EnvironmentButtons = ({ project }) => {
  const intl = useIntl();
  const { organizationId, environmentId } = useDecodedParams();

  const environment = useSelectorWithUrlParams(environmentSelector);

  const sshUrls = environment ? environment.getSshUrls() : {};
  const sshCommands =
    sshUrls &&
    Object.keys(sshUrls).map(key => sshUrls[key].replace("ssh://", "ssh "));
  const cliCommand = getEnvironmentGitCommand(
    project,
    environment,
    intl.formatMessage({
      id: "CLI_command_name"
    }),
    environmentId ? "git" : "git-project"
  );
  const platformCliCommand = getEnvironmentGitCommand(
    project,
    environment,
    intl.formatMessage({
      id: "CLI_command_name"
    }),
    environmentId ? "cli" : "cli-project"
  );

  return (
    <S.Layout aria-label="Environment buttons">
      <S.Wrapper className="info-buttons">
        {environment && (
          <ActionDropdown
            id="environment-urls"
            label={intl.formatMessage({ id: "urls" })}
            className={environment.status === "inactive" ? "disabled" : ""}
            status={environment.status}
          >
            <EnvironmentRoutes
              projectId={project.id}
              organizationId={organizationId}
              environmentId={environmentId}
              hideRedirects
            />
          </ActionDropdown>
        )}

        {environment && sshCommands.length > 0 && (
          <ActionDropdown
            id="environment-ssh"
            label="SSH"
            className={environment.status === "inactive" ? "disabled" : ""}
            status={environment.status}
          >
            {sshCommands.map((ssh, index) => (
              <CopyableArea
                id={`environment-ssh-cmd-${index}`}
                key={ssh}
                content={ssh}
                singleLine={true}
              >
                {ssh}
              </CopyableArea>
            ))}
          </ActionDropdown>
        )}

        {cliCommand && platformCliCommand && (
          <Code gitCommand={cliCommand} platformCommand={platformCliCommand} />
        )}
      </S.Wrapper>
    </S.Layout>
  );
};

EnvironmentButtons.propTypes = {
  project: PropTypes.object.isRequired
};

export default EnvironmentButtons;
