import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";

import useDecodedParams from "Hooks/useDecodedParams";
import useSelectorWithUrlParams from "Hooks/useSelectorWithUrlParams";

import {
  environmentSelector,
  environmentLoadingSelector,
  environmentErrorSelector
} from "Reducers/environment";

import Button from "Components/Button";
import FullPageError from "Components/FullPageError";

const EnvironmentError = ({ children }) => {
  const intl = useIntl();
  const { organizationId, projectId } = useDecodedParams();

  const environment = useSelectorWithUrlParams(environmentSelector);
  const isLoading = useSelectorWithUrlParams(environmentLoadingSelector);
  const error = useSelectorWithUrlParams(environmentErrorSelector);
  const environmentLoadingError = useMemo(
    () => (error?.toJS ? error.toJS() : error),
    [error]
  );

  let errorTitle = intl.formatMessage({ id: "error_loading_environment" });
  let errorMessage = intl.formatMessage({
    id: "error_loading_environment_description"
  });

  if (!isLoading && environmentLoadingError) {
    if (environmentLoadingError.code === 403) {
      errorTitle = intl.formatMessage({ id: "error_403_title" });
      errorMessage = intl.formatMessage({ id: "error_environment_403" });
    }
  }

  const shouldShowError =
    !isLoading && (environmentLoadingError || !environment);

  return shouldShowError ? (
    <FullPageError title={errorTitle} message={errorMessage}>
      <div className="actions">
        <Button
          className="primary"
          as={Link}
          to={`/${organizationId}/${projectId}`}
          aria-label="Return to project"
        >
          Return to project
        </Button>
      </div>
    </FullPageError>
  ) : (
    children
  );
};

EnvironmentError.propTypes = {
  children: PropTypes.node
};

export default EnvironmentError;
