import React, { useEffect, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { useIntl } from "react-intl";
import Ansi from "ansi-to-react";
import stripAnsi from "strip-ansi";
import moment from "moment";

import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  List
} from "react-virtualized";
import "react-virtualized/styles.css";

import client from "Libs/platform";
import useDecodedParams from "Hooks/useDecodedParams";
import { loadLogFromActivity } from "Reducers/log";

import { ScrollDownIcon, ScrollTopIcon } from "@platformsh/ui-kit";

import ActivityHeader from "../../activities/Activity/ActivityHeader";
import ModalWrapper from "Components/Modal";
import ShareIcon from "Icons/ShareIcon";
import DownloadIcon from "Icons/DownloadIcon";
import { downloadBlob } from "Libs/utils";
import AccessibleTooltip from "Components/AccessibleTooltip";

import * as S from "./LogModal.styles";

const logs2Text = logs => {
  return logs.reduce((logText, jsLog) => {
    if (!jsLog || !jsLog.data) {
      return logText;
    }
    return `${logText} ${stripAnsi(jsLog.data.message)}`;
  }, "");
};

const cache = new CellMeasurerCache({
  defaultHeight: 30,
  fixedWidth: true
});

const LogModal = ({ closeModal }) => {
  const intl = useIntl();
  const { push } = useHistory();
  const { hash } = useLocation();
  const dispatch = useDispatch();
  const logWrapper = useRef();
  const listLog = useRef();
  const { activityId, projectId } = useDecodedParams();

  const [activity, setActivity] = useState();
  const [linesSelected, setLinesSelected] = useState({
    start: null,
    end: null,
    scrollTo: false
  });
  const [selection, setSelection] = useState();
  const [displayScrollBtns, setDisplayScrollBtns] = useState({
    toTop: false,
    toBottom: true
  });
  const [visibleIndex, setVisibleIndex] = useState({
    startIndex: 0,
    stopIndex: 0
  });

  const jsLog = useSelector(({ log }) =>
    log?.getIn(["data", activity?.id, "log"], [])
  );
  const loading = useSelector(({ log }) =>
    log?.getIn(["data", activity?.id, "loading"], true)
  );

  const logStreamstreamEnded = useSelector(({ log }) =>
    log?.getIn(["data", activity?.id, "streamEnded"])
  );

  const organization = useSelector(({ project }) =>
    project?.getIn(["orgByProjectId", activity?.project])
  );
  const project = useSelector(({ project }) =>
    project?.getIn(["data", organization, activity?.project])
  );

  useEffect(() => {
    const regex = /^#L(\d*)(-L(\d*))?/;
    const match = hash.match(regex);
    if (match) {
      setLinesSelected({
        start: Number(match[1]),
        end: match[3] ? Number(match[3]) : null,
        scrollTo: true
      });
    }
  }, []);

  useEffect(() => {
    const loadActivity = async () => {
      const result = await client.getProjectActivity(projectId, activityId);
      setActivity(result);
    };
    if (activityId) loadActivity();
  }, [activityId]);

  useEffect(() => {
    if (activity?.id) dispatch(loadLogFromActivity({ activity }));
  }, [activity]);

  useEffect(() => {
    const { start, end } = linesSelected;
    let newHash = "#";
    if (start) newHash += `L${start}`;
    if (end) newHash += `-L${end}`;
    if (hash !== newHash) push({ hash: newHash });
  }, [linesSelected]);

  useEffect(() => {
    setDisplayScrollBtns({
      toTop: visibleIndex.startIndex > 0,
      toBottom: visibleIndex.stopIndex + 1 < itemCount
    });
  }, [visibleIndex]);

  const onRowsRendered = ({ startIndex, stopIndex }) => {
    setVisibleIndex({ startIndex, stopIndex });
  };

  const fullLogs = useMemo(() => {
    if (!jsLog.length) return "";
    return logs2Text(jsLog);
  }, [jsLog]);

  const copyText = useMemo(() => {
    const { start, end } = linesSelected;

    // If user selects lines
    if (selection) return selection;

    // When only one line is selected
    if (typeof start === "number" && !end && jsLog[start]) {
      return stripAnsi(jsLog[start].data.message);
    }

    // When multilines are selected
    if (typeof start === "number" && end) {
      return logs2Text(jsLog.slice(start, end + 1));
    }

    return fullLogs;
  }, [linesSelected, fullLogs, selection]);

  const itemCount = useMemo(() => {
    if (!jsLog.length) return 0;
    const length = jsLog.length;
    return loading ? length + 1 : length;
  }, [jsLog.length, loading]);

  const height = useMemo(() => {
    const offsetHeight = logWrapper.current?.offsetHeight;
    return offsetHeight > 0 ? offsetHeight - 10 : 50;
  }, [logWrapper.current, loading]);

  const selectLine = (e, line) => {
    e.preventDefault();
    e.stopPropagation();
    const { start, end } = linesSelected;

    if (start === line) {
      setLinesSelected({ start: end, end: null, scrollTo: false });
      return;
    }

    if (e.shiftKey) {
      if (!start && !end) {
        setLinesSelected({ start: line, end: null, scrollTo: false });
      } else if (line < start) {
        setLinesSelected({ start: line, end: start, scrollTo: false });
      } else {
        setLinesSelected({ ...linesSelected, end: line, scrollTo: false });
      }
    } else {
      setLinesSelected({ start: line, end: null, scrollTo: false });
    }
  };

  const handleSelect = e => {
    e.preventDefault();
    setSelection(window.getSelection().toString());
  };

  const isSelected = index => {
    const { start, end } = linesSelected;
    if (!end) {
      return start === index;
    }
    return start <= index && index <= end;
  };

  const showTimeStamp = index => {
    const { start, end } = linesSelected;
    return index === start || index === end;
  };

  const [logs, selectedText] = [
    fullLogs,
    // Remove Line numbers from copied build logs
    copyText.replaceAll(/\n(\d+)(.*)/gi, "\n$2")
  ];

  const onDownloadLog = () => {
    downloadBlob(
      selectedText || logs,
      { type: '"data:attachment/text"' },
      "build_log.txt"
    );
  };

  const recomputeRowHeights = () => {
    cache.clearAll();
    listLog.current?.recomputeRowHeights();
  };

  const scrollTo = (e, index) => {
    e.preventDefault();
    listLog.current?.scrollToRow(index);
    setLinesSelected({ ...linesSelected, scrollTo: false });
  };

  const scrollToIndex = useMemo(() => {
    const { scrollTo, start } = linesSelected;
    return scrollTo && jsLog[start] ? start : undefined;
  }, [linesSelected, loading]);

  // eslint-disable-next-line react/prop-types
  const rowRenderer = ({ index, key, parent, style }) => {
    if (loading || itemCount === 0) return;
    const logIndex = index - 1;
    let log;
    if (index !== 0 || index === itemCount) {
      log = jsLog[logIndex];
    }
    return (
      <CellMeasurer
        cache={cache}
        columnIndex={0}
        key={key}
        parent={parent}
        rowIndex={index}
      >
        {({ registerChild }) => (
          <>
            {log ? (
              <S.Line
                ref={registerChild}
                style={style}
                selected={isSelected(index)}
                showTimeStamp={showTimeStamp(index)}
              >
                <S.LineNumber onClick={e => selectLine(e, index)}>
                  {index}
                </S.LineNumber>
                <Ansi>{log.data.message}</Ansi>
                <S.TimeStamp>
                  {logIndex !== linesSelected.end ? (
                    <>{moment(log.data.timestamp).format("LTS")}</>
                  ) : (
                    <>
                      {moment
                        .duration(
                          moment(log.data.timestamp).diff(
                            moment(jsLog[linesSelected.start]?.data?.timestamp)
                          )
                        )
                        .asSeconds()}{" "}
                      sec
                    </>
                  )}
                </S.TimeStamp>
              </S.Line>
            ) : (
              <S.Spacer ref={registerChild} style={style} />
            )}
          </>
        )}
      </CellMeasurer>
    );
  };

  return (
    <ModalWrapper
      isOpen
      closeModal={closeModal}
      modalClass="modal-build-log modal-fullpage"
    >
      <S.Header>
        <S.Title>
          {[project?.name, activity?.environments[0]]
            .filter(item => item)
            .join(" / ")}
        </S.Title>
        <S.Org>
          {organization}
          <span> </span>
        </S.Org>
      </S.Header>

      <S.BuildLogHeader>
        {activity && <ActivityHeader activity={activity} />}
      </S.BuildLogHeader>

      {activity && (
        <S.ActivitiyActionContainer>
          <S.LogTitle>{intl.formatMessage({ id: "build_log" })}</S.LogTitle>
          {logStreamstreamEnded && !!logs.length && (
            <S.ActionWrapper>
              <AccessibleTooltip
                tooltipProps={{
                  children: intl.formatMessage({ id: "copy_build_log" })
                }}
              >
                <S.ActionIcon
                  text={selectedText || logs}
                  title={intl.formatMessage({ id: "icons.copy_log" })}
                  variant="secondary"
                />
              </AccessibleTooltip>
              <AccessibleTooltip
                tooltipProps={{
                  children: intl.formatMessage({ id: "download_build_log" })
                }}
              >
                <S.ActionIcon
                  onClick={onDownloadLog}
                  icon={<DownloadIcon aria-hidden="true" />}
                  title={intl.formatMessage({ id: "icons.download" })}
                  variant="secondary"
                />
              </AccessibleTooltip>
              <AccessibleTooltip
                tooltipProps={{
                  children: intl.formatMessage({ id: "copy_build_url" })
                }}
              >
                <S.ActionIcon
                  text={window.location.href}
                  variant="secondary"
                  title={intl.formatMessage({ id: "icons.share_link" })}
                  icon={<ShareIcon aria-hidden="true" />}
                />
              </AccessibleTooltip>

              <S.ScrollButtons>
                <AccessibleTooltip
                  tooltipProps={{
                    children: intl.formatMessage({ id: "scroll_top" })
                  }}
                >
                  <S.ScrollButton
                    bottom
                    onClick={e => scrollTo(e, 0)}
                    variant="secondary"
                    content="icon"
                    disabled={!displayScrollBtns.toTop}
                  >
                    <ScrollTopIcon />
                  </S.ScrollButton>
                </AccessibleTooltip>

                <AccessibleTooltip
                  tooltipProps={{
                    children: intl.formatMessage({ id: "scroll_down" })
                  }}
                >
                  <S.ScrollButton
                    top
                    onClick={e => scrollTo(e, itemCount - 1)}
                    variant="secondary"
                    content="icon"
                    disabled={!displayScrollBtns.toBottom}
                  >
                    <ScrollDownIcon />
                  </S.ScrollButton>
                </AccessibleTooltip>
              </S.ScrollButtons>
            </S.ActionWrapper>
          )}
        </S.ActivitiyActionContainer>
      )}

      {project && activity && (
        <S.LogPre
          ref={logWrapper}
          showLinesNumber={!loading && logs?.length > 0}
        >
          <S.Container>
            <AutoSizer
              disableHeight
              onResize={recomputeRowHeights}
              style={{ height: "100%" }}
            >
              {({ width }) => (
                <List
                  ref={listLog}
                  deferredMeasurementCache={cache}
                  height={height}
                  onRowsRendered={onRowsRendered}
                  noRowsRenderer={() =>
                    loading ? (
                      <S.DotDotDot>
                        {intl.formatMessage({ id: "loading" })}
                      </S.DotDotDot>
                    ) : (
                      <S.Empty>
                        {intl.formatMessage({ id: "log_empty" })}
                      </S.Empty>
                    )
                  }
                  onMouseUp={handleSelect}
                  overscanCount={100}
                  rowCount={itemCount + 2} // add 2 lines to create padding
                  rowHeight={cache.rowHeight}
                  rowRenderer={rowRenderer}
                  scrollToAlignment="center"
                  scrollToIndex={scrollToIndex}
                  width={width}
                />
              )}
            </AutoSizer>
          </S.Container>
        </S.LogPre>
      )}
    </ModalWrapper>
  );
};

LogModal.propTypes = {
  closeModal: PropTypes.func.isRequired
};

export default LogModal;
