import React from "react";
import PropTypes from "prop-types";

import ButtonAdd from "Components/ButtonAdd";

import Filters from "../Filters";
import * as S from "./styles";

const TableFilters = ({
  title,
  isLoading,
  search,
  onFilterChange,
  sortByOptions,
  onAddButtonClick,
  addButtonText,
  showFilter = true,
  showAddButton = true
}) => {
  return (
    <S.TableFilters>
      <S.TableHeading>{title}</S.TableHeading>

      {!isLoading && showFilter && (
        <Filters
          search={search}
          onFilterChange={onFilterChange}
          sortByOptions={sortByOptions}
        />
      )}
      {showAddButton && (
        <ButtonAdd onClick={onAddButtonClick} customText={addButtonText} />
      )}
    </S.TableFilters>
  );
};

TableFilters.propTypes = {
  title: PropTypes.string,
  isLoading: PropTypes.bool,
  showFilter: PropTypes.bool,
  showAddButton: PropTypes.bool,
  search: PropTypes.string,
  onFilterChange: PropTypes.func,
  sortByOptions: PropTypes.object,
  onAddButtonClick: PropTypes.func,
  addButtonText: PropTypes.string
};
export default TableFilters;
