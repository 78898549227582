import styled, { css } from "styled-components";

import { getCSSVarString, SECTION } from "Libs/themes";

export const Layout = styled.span`
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  padding: 0 8px;
  text-transform: capitalize;
  border-radius: 2px;
  white-space: nowrap;
  display: inline-flex;
  height: 24px;
  justify-content: center;
  align-items: center;

  background-color: ${({ status }) => {
    switch (status) {
      case "deleted":
      case "closed":
      case "solved":
      case "hold":
        return "rgba(201, 208, 229, 0.16)";
      case "new":
        return "rgba(0, 200, 156, 0.16)";
      case "open":
        return "rgba(71, 134, 255, 0.16)";
      case "pending":
        return "rgba(255, 131, 89, 0.16)";
      default:
        return"var(--section-transparent-background-color,var(--section-transparent,var(--transparent)))";
    }
  }};

  color: ${({ status }) => {
    switch (status) {
      case "deleted":
      case "closed":
        return"var(--section-granite-color,var(--section-granite,var(--granite)))";
      case "solved":
      case "hold":
        return"var(--section-slate-color,var(--section-slate,var(--slate)))";
      case "new":
        return"var(--section-java-color,var(--section-java,var(--java)))";
      case "open":
        return"var(--section-skye-color,var(--section-skye,var(--skye)))";
      case "pending":
        return"var(--section-fire-color,var(--section-fire,var(--fire)))";
      default:
        return"var(--section-ebony-light-color,var(--section-ebony-light,var(--ebony-light)))";
    }
  }};

  ${props =>
    props.plain &&
    css`
      background-color: transparent;
      color: #302f45;
      padding: 0;
      height: auto;
      font-weight: bold;
    `};
`;
