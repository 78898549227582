import styled, { css as styledCSS } from "styled-components";

import { semiBoldAlias, shadows } from "Libs/theme";
import { css, HEADER, SECTION } from "Libs/themes";

import CloseBtn from "Components/CloseBtn";

export const Layout = styled.div`
  display: flex;
  flex-direction: row;
  padding: 16px;
  background-color:var(--section-snow-background-color,var(--section-snow,var(--snow)));
  position: relative;
  border-radius: 2px;
  ${shadows.lg};
  max-width: 400px;

  ${props =>
    props.singleLine &&
    styledCSS`
      align-items: center;
    `};
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: ${props => (props.singleLine ? "center" : "flex-start")};
`;

export const Content = styled.div`
  padding-left: 16px;
  flex: 1;
  padding-right: 24px;
`;

export const Title = styled.div`
  ${semiBoldAlias};
  font-size: 14px;
  line-height: 24px;
  color:var(--header-ebony-color,var(--header-ebony,var(--ebony)));
  ${props =>
    props.singleLine &&
    styledCSS`
      display: flex;
      align-items: center;
      justify-content: space-between;
    `};
`;

export const Description = styled.div`
  font-size: 14px;
  line-height: 24px;
  color:var(--header-ebony-color,var(--header-ebony,var(--ebony)));
  margin-top: 4px;
  margin-bottom: 4px;
`;

export const Close = styled(CloseBtn)`
  position: absolute;
  top: 12px;
  right: 12px;
`;
