import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { getCSSVarString, SECTION } from "Libs/themes";

import Loading from "Components/Loading";
import CheckboxField from "Components/fields/CheckboxField";

const Layout = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
  .field,
  label {
    margin-bottom: 0;
  }
  &:only-child {
    margin-bottom: 0;
  }
`;

const Field = styled.div`
  width: 100%;
`;

const Description = styled.div`
  font-size: 15px;
  var(--section-slate-color,var(--section-slate,var(--slate)));
  margin-bottom: 24px;
`;

const ToggleSwitch = ({
  id,
  title,
  description,
  onClick,
  value,
  enabled = true,
  loading
}) => (
  <Layout className="toggle-field">
    <Field>
      <Description className="toggle-description">{description}</Description>
      {loading ? (
        <Loading />
      ) : (
        <CheckboxField
          label={title}
          type="checkbox"
          value={value}
          onChange={onClick}
          disabled={!enabled}
          aria-disabled={!enabled}
          forId={id}
        />
      )}
    </Field>
  </Layout>
);

ToggleSwitch.propTypes = {
  title: PropTypes.string,
  description: PropTypes.node,
  onClick: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  enabled: PropTypes.bool,
  loading: PropTypes.bool,
  id: PropTypes.string
};

export default ToggleSwitch;
